<template>
  <div>
    <header :class="$style.header">
      <div :class="$style.searchInputContainer">
        <BaseInput
          :class="$style.searchInput"
          type="text"
          :placeholder="`Search ${
            Number.isInteger(matchingTracksCount)
              ? `${matchingTracksCount} results . . .`
              : ''
          }`"
          :value="query"
          @input="$emit('update:query', $event)"
        />
      </div>
    </header>
    <main>
      <div ref="tableContainer" :class="$style.tableContainer">
        <BaseSpinner v-if="searchTracksStatusPending && !tracks.length" show />
        <table v-else :class="$style.table">
          <thead :class="$style.tableHeader">
            <tr>
              <th>
                <input
                  type="checkbox"
                  :checked="
                    tracks.length && selectedTracksNum === tracks.length
                  "
                  @change="onChangeAllSelectedTracks"
                />
              </th>
              <th>ISRC</th>
              <th>Track</th>
              <th>Performers</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{ id, title, performers, isrc } of tracks" :key="id">
              <td>
                <input
                  :checked="selectedTracks[id]"
                  type="checkbox"
                  @change="onChangeSelectedTrack($event, id, isrc)"
                />
              </td>
              <td>{{ isrc }}</td>
              <td>
                <button
                  :class="$style.titleButton"
                  @click.prevent="
                    openTrackDetailsModal({
                      title,
                      isrc,
                    })
                  "
                >
                  {{ title }}
                </button>
              </td>
              <td>{{ performers }}</td>
            </tr>
            <!-- <LoadMore
              v-if="tracks.length"
              tag="tr"
              @loadMore="onLoadMoreItems"
            /> -->
          </tbody>
        </table>
      </div>
    </main>
    <TrackDetailsModal
      :fetchTrackDetailsStatus="fetchTrackDetailsStatus"
      :track="tracksDetails[showTrackDetailsIsrc]"
      :tracks="tracks"
      @next="openTrackDetailsModal"
      @prev="openTrackDetailsModal"
    />
  </div>
</template>

<script>
import LoadMore from "@/components/common/LoadMore";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { IDLE, PENDING, SUCCESS, ERROR } from "@/constants/apiStatus";
import { withAsync } from "@/helpers";
import { fetchPlaylistDetails } from "@/api/isrcApi";
import TrackDetailsModal from "../../upload/components/TrackDetailsModal";
export default {
  name: "SearchResults",
  components: {
    LoadMore,
    TrackDetailsModal,
  },
  props: {
    query: {
      type: String,
      default: "",
    },
    tracks: {
      type: Array,
      default: () => [],
    },
    searchTracksStatusPending: {
      type: Boolean,
      default: false,
    },
    matchingTracksCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedTracks: {},
      tracksDetails: {},
      fetchTrackDetailsStatus: IDLE,
      showTrackDetailsIsrc: null,
      selectedTracksNum: 0,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchTrackDetailsStatus"),
  },
  methods: {
    async openTrackDetailsModal(track) {
      console.log("open track", track);
      const { isrc } = track;
      this.showTrackDetailsIsrc = isrc;
      this.$vfm.show("track-details-modal");
      if (this.tracksDetails[isrc]) return;
      this.tracksDetails[isrc] = track;
      this.fetchTrackDetailsStatus = PENDING;
      const { response, error } = await withAsync(() =>
        fetchPlaylistDetails([isrc])
      );

      if (error) {
        console.error(error);
        this.fetchTrackDetailsStatus = ERROR;
        return;
      }

      this.tracksDetails[isrc] = this.prepareTrackDetailsResponse(
        response,
        track
      );
      this.fetchTrackDetailsStatus = SUCCESS;
    },
    prepareTrackDetailsResponse(response, track) {
      const writerRoles = ["C", "A"];
      const writers = [];
      const publishers = [];
      for (const item of response) {
        const {
          interested_party,
          artist_name,
          role,
          society,
          ipi,
          party_admin,
        } = item;
        if (
          writerRoles.some((writerRole) =>
            role.toLowerCase().includes(writerRole.toLowerCase())
          )
        ) {
          writers.push({
            interested_party,
            artist_name,
            role,
            society,
            ipi,
          });
        } else {
          publishers.push({
            interested_party,
            party_admin,
            role,
            society,
            ipi,
          });
        }
      }

      return {
        ...track,
        writers,
        publishers,
      };
    },
    selectAllTracks() {
      this.selectedTracks = this.trackIds.reduce((acc, trackId) => {
        acc[trackId] = true;
        return acc;
      }, {});
    },
    deselectAllTracks() {
      this.selectedTracks = {};
    },
    onChangeAllSelectedTracks(e) {
      if (e.target.checked) {
        this.selectAllTracks();
      } else {
        this.deselectAllTracks();
      }
    },
  },
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.searchInputContainer {
  flex-grow: 1;
}

.searchInput {
  width: 100%;
  border: none;
  font-size: 32px;
  font-weight: 500;
  color: $primary-text;
  &::placeholder {
    color: #bfc9d9;
  }
}

.tableContainer {
  position: relative;
  max-height: calc(100vh - 8rem - 170px);
  overflow-y: auto;
}

.table {
  position: relative;
  td {
    color: $primary-text;
    padding: 0 0.5rem;
    line-height: 2;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
}

.tableHeader {
  position: sticky;
  top: 0;
  background-color: #f8f9fb;
  padding: 0 1rem;

  th {
    padding: 0.5rem;
    color: $primary-text;
    display: table-cell !important;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
}

.titleButton {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 22px;
  text-align: left;
  font-weight: 400;
  color: $primary-text;
  cursor: pointer;
  font-family: helveticaneue;
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.5px;
}
</style>